import { ElementRef, FC, useCallback, useEffect, useRef, useState } from 'react';
import { FlowProps } from './RegisterFlow';
import QRCode from 'react-qr-code';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { Trans, useTranslation } from 'react-i18next';
import OTPInput from '../../../components/shared/form-control/OTPInput';
import AuthService from '../../../services/AuthService';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import DateUtils from '../../../utils/DateUtils';
import { useCountdown } from '../../../hooks/useCountdown';
import { useNavigate } from 'react-router-dom';
import StorageService from '../../../services/StorageService';

const TIMEOUT_LENGTH = 5 * 60; // 5 minutes
type PageMode = 'QR' | 'KEY';

const Set2FA: FC<FlowProps> = (props) => {
  const { next, push, userEmail } = props;
  const [mode, setMode] = useState<PageMode>('QR');
  const [loading, setLoading] = useState(false);
  const toasts = useToasts();
  const timeLeft = useCountdown(TIMEOUT_LENGTH);
  const navigate = useNavigate();

  const otpRef = useRef<ElementRef<typeof OTPInput>>(null);

  const [qrUrl, setQrUrl] = useState('');
  const [secretKey, setSecretKey] = useState('');

  useEffect(() => {
    AuthService.setup2FA().then((res) => {
      setQrUrl(`otpauth://totp/Playbook:${userEmail}?secret=${res.data.secret}&issuer=Playbook`);
      setSecretKey(res.data.secret);
    });
  }, [userEmail]);

  const { t } = useTranslation('auth');

  const switchMode = useCallback(() => {
    setMode((prev) => (prev === 'QR' ? 'KEY' : 'QR'));
  }, []);

  const onComplete = useCallback(
    (pin: string) => {
      setLoading(true);
      AuthService.activate2FA(pin)
        .then((res) => {
          StorageService.setLang(res.data.user.language);
          if (res.data.activated) {
            next();
          } else {
            otpRef.current?.clearOtp();
          }
        })
        .catch(() => {
          otpRef.current?.clearOtp();
          toasts.addToast({
            title: t('otp.toasts.invalid.title'),
            type: ToastType.ERROR,
            description: t('otp.toasts.invalid.description'),
            expiresInMs: 10000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [next, t, toasts],
  );

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.set-2fa.heading')}</Heading>

      <div className="text-center">
        {mode === 'QR' ? (
          <div data-cy="setup-2fa-with-qr">
            <div className="font-medium">{t('registration.set-2fa.subheading')}</div>
            <div className="bg-accent-light-mid mx-auto my-4 w-3/4 p-6">
              <div className="pb-4 font-medium">{t('registration.set-2fa.subheading-qr')}</div>
              <div className="ml-4">
                <ol>
                  <li>{t('registration.set-2fa.explaination.step-1')}</li>
                  <li>{t('registration.set-2fa.explaination.step-2')}</li>
                  <li>{t('registration.set-2fa.explaination.step-3')}</li>
                  <li>{t('registration.set-2fa.explaination.step-4')}</li>
                </ol>
                <p>{t('registration.set-2fa.explaination.end')}</p>
              </div>
            </div>
            <div className="flex justify-center p-8" data-cy="2fa-qr-code">
              {qrUrl && <QRCode value={qrUrl} size={164} />}
            </div>

            <div className="my-4 cursor-pointer font-medium hover:underline" onClick={switchMode} data-cy="switch-to-key-mode">
              {t('registration.set-2fa.switch-to-key')}
            </div>
          </div>
        ) : (
          <div data-cy="setup-2fa-with-key">
            <div>{t('registration.set-2fa.subheading-key')}</div>

            <div className="bg-accent-light-mid my-4 px-3 py-6 font-medium" style={{ letterSpacing: '.2rem' }} data-cy="2fa-key">
              {secretKey}
            </div>

            <div className="my-4 cursor-pointer font-medium hover:underline" onClick={switchMode} data-cy="switch-to-qr-mode">
              {t('registration.set-2fa.switch-to-qr')}
            </div>
          </div>
        )}

        <div>{t('registration.set-2fa.enter-code')}</div>
        <div className="w-128 mx-auto">
          <OTPInput ref={otpRef} onComplete={onComplete} disabled={loading} />
        </div>

        {timeLeft > 0 ? (
          <div className="text-accent-3 py-4">
            <Trans
              t={t}
              i18nKey="registration.set-2fa.otp-expiring-text"
              values={{ time: DateUtils.formatSeconds(timeLeft) }}
              components={{ Bold: <span className="font-medium" /> }}
            />{' '}
          </div>
        ) : (
          <div className="text-semantic-light-4 py-4">{t('registration.set-2fa.otp-expired-text')}</div>
        )}

        <span
          className="cursor-pointer font-medium hover:underline"
          onClick={() => (timeLeft > 0 ? push('2fa-info') : navigate('/auth/login'))}
          data-cy="2fa-info-step"
        >
          {t('registration.set-2fa.back')}
        </span>
      </div>
    </>
  );
};

export default Set2FA;
